import React from "react";
import { useState } from "react";
import fb from "../images/icons/facebook.svg";
import insta from "../images/icons/insta.svg";
import { Link, NavLink } from "react-router-dom";
import BrandNav from "./BrandNav";
import CorbyLogo from "../images/corby-logo.png";
export default function Header() {
  //const closeOpenNav = () => {
  //  closeBrandNav();
  //};
  const [brandNavOpen, setBrandNavOpen] = useState(false);
  const closeBrandNav = () => {
    setBrandNavOpen(false);
  };
  function clear() {
    window.localStorage.clear();
  }
  document.addEventListener("click", (e) => {
    if (
      (e.target.id === "brandNavContainer" ||
        e.target.id === "brand-nav-category-title") &&
      brandNavOpen
    ) {
      return;
    } else if (e.target.id !== "brandNavContainer" && brandNavOpen) {
      let obj = e.target;
      if (obj.classList[0] === "brand-nav-category-title") {
        return;
      }
      closeBrandNav();
    }
  });
  return (
    <header>
      <div className="header-container">
        <Link to="/" className="header-logo">
          {/*Corby's Audio*/}
          <img src={CorbyLogo} alt="Corby Audio" />
        </Link>
        <div className="header-nav-menu" role="menubar">
          <div className="nav-item">
            <div
              id="brandNavContainer"
              onClick={() => setBrandNavOpen(!brandNavOpen)}
            >
              Brands
            </div>
            <BrandNav
              isOpen={brandNavOpen}
              setClosed={closeBrandNav}
            ></BrandNav>
          </div>
          <div className="nav-item">
            <NavLink
              role="menuitem"
              to="/News"
              className="nav-link"
              onClick={() => setBrandNavOpen(false)}
            >
              News
            </NavLink>
          </div>
          <div className="nav-item">
            {" "}
            <NavLink
              role="menuitem"
              to="/About"
              className="nav-link"
              onClick={() => setBrandNavOpen(false)}
            >
              About
            </NavLink>
          </div>
          <div className="nav-item">
            <NavLink role="menuitem" to="/Shop/" className="nav-link">
              Shop
            </NavLink>
          </div>
          <div className="nav-item">
            <a
              href="https://www.facebook.com/corbysaudio"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
              role="menuitem"
              aria-label="Link opens facebook.com"
            >
              <img src={fb} alt="" />
            </a>
          </div>
          <div className="nav-item">
            <a
              href="https://www.instagram.com/corbysaudio/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
              role="menuitem"
              aria-label="link opens instagram.com"
            >
              <img src={insta} alt="" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
